import { planYourCareerBannerBg } from 'containers/App/constants';
import styled from 'styled-components';
import { primaryDarkNew, primaryNew, white } from 'themes/variables';

export const PlanYourCareerBannerContainer = styled.div`
  background-image: url(${planYourCareerBannerBg});
  color: rgb(${primaryDarkNew});
  padding: 2.5em;
  background-size: cover;
  border-radius: 16px;
  margin-bottom: 1em;
`;

export const CustomCardContainer = styled.div`
  /* position: absolute; */
  margin: 0.5em;
  max-width: 20%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-height: 77vh;
  overflow-y: auto;
  flex-basis: 25%;
`;

export const ReactFlowContainer = styled.div`
  /* width: 100%; */
  height: 80vh;
  border: 1px solid rgb(${primaryNew}, 0.1);
  border-radius: 4px;
  display: flex;
  background: rgb(${white});
  .react-flow {
    &__handle {
      visibility: hidden;
    }
  }
`;

export const ControlsContainer = styled.div`
  background-color: rgb(${primaryNew});
  border-radius: 8px;
  padding: 1em;
  display: flex;
  column-gap: 10px;
  svg {
    cursor: pointer;
  }
`;
