/*
 * MyProfilePage Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'Notchup.containers.MyProfilePage';

export default defineMessages({
  title: {
    id: `${scope}.title.head`,
    defaultMessage: 'My Profile',
  },
  metaTitle: {
    id: `${scope}.metaTitle.head`,
    defaultMessage: 'My Profile',
  },
  titleRate: {
    id: `${scope}.titleRate`,
    defaultMessage: 'Rate',
  },
  titleRolePlayed: {
    id: `${scope}.titleRolePlayed`,
    defaultMessage: 'Role Played',
  },
  titleProductURL: {
    id: `${scope}.titleProductURL`,
    defaultMessage: 'Product URL',
  },
  titleDISCProfile: {
    id: `${scope}.titleDISCProfile`,
    defaultMessage: 'DISC Profile',
  },
  titleTeamWorkingStyle: {
    id: `${scope}.titleTeamWorkingStyle`,
    defaultMessage: 'Team Working Style',
  },
  titleProfessionalProfiles: {
    id: `${scope}.titleProfessionalProfiles`,
    defaultMessage: 'Professional Profiles',
  },
  titlePreferences: {
    id: `${scope}.titlePreferences`,
    defaultMessage: 'Preferences',
  },
  titleSkills: {
    id: `${scope}.titleSkills`,
    defaultMessage: 'Skill Graph',
  },
  titleSoftSkills: {
    id: `${scope}.titleSoftSkills`,
    defaultMessage: 'Soft skills',
  },
  titleLanguages: {
    id: `${scope}.titleLanguages`,
    defaultMessage: 'Languages',
  },
  titleEducation: {
    id: `${scope}.titleEducation`,
    defaultMessage: 'Education',
  },
  titleKeyProjects: {
    id: `${scope}.titleKeyProjects`,
    defaultMessage: 'Key Projects',
  },
  titleWorkExperience: {
    id: `${scope}.titleWorkExperience`,
    defaultMessage: 'Work Experience',
  },
  titleProjectDescription: {
    id: `${scope}.titleProjectDescription`,
    defaultMessage: 'Project Description',
  },
  titleDescription: {
    id: `${scope}.titleDescription`,
    defaultMessage: 'Description',
  },
  titleAssignments: {
    id: `${scope}.titleAssignments`,
    defaultMessage: 'Assignment',
  },
  noCertificate: {
    id: `${scope}.noCertificate`,
    defaultMessage: 'No certificate is added',
  },
  noKeyProject: {
    id: `${scope}.noKeyProject`,
    defaultMessage: 'No project is added',
  },
  noWorkExperience: {
    id: `${scope}.noWorkExperience`,
    defaultMessage: 'No work experience is added',
  },
  noEducation: {
    id: `${scope}.noEducation`,
    defaultMessage: 'No qualification is added',
  },
  modelChangePhotoHeader: {
    id: `${scope}.modelChangePhotoHeader`,
    defaultMessage: 'Edit or Change photo',
  },
  modelEditIntroHeader: {
    id: `${scope}.modelEditIntroHeader`,
    defaultMessage: 'Edit Intro',
  },
  modelEditPreferenceHeader: {
    id: `${scope}.modelEditPreferenceHeader`,
    defaultMessage: 'Edit Preferences',
  },
  modalEditAvailabilityHeader: {
    id: `${scope}.modalEditAvailabilityHeader`,
    defaultMessage: 'Edit Availability',
  },
  modalEditRateHeader: {
    id: `${scope}.modalEditRateHeader`,
    defaultMessage: 'Edit Rate',
  },
  modalAddWorkExperienceHeader: {
    id: `${scope}.modalAddWorkExperienceHeader`,
    defaultMessage: 'Add work experience',
  },
  modalEditWorkExperienceHeader: {
    id: `${scope}.modalEditWorkExperienceHeader`,
    defaultMessage: 'Edit work experience',
  },
  modalAddEducationHeader: {
    id: `${scope}.modalAddEducationHeader`,
    defaultMessage: 'Add Education',
  },
  modalEditEducationHeader: {
    id: `${scope}.modalEditEducationHeader`,
    defaultMessage: 'Edit Education',
  },
  modalAddCertificateHeader: {
    id: `${scope}.modalAddCertificateHeader`,
    defaultMessage: 'Add Certificate',
  },
  modalEditCertificateHeader: {
    id: `${scope}.modalEditCertificateHeader`,
    defaultMessage: 'Edit Certificate',
  },

  titlePreview: {
    id: `${scope}.titlePreview`,
    defaultMessage: 'Preview',
  },
  applyButton: {
    id: `${scope}.applyButton`,
    defaultMessage: 'Apply',
  },
  addButton: {
    id: `${scope}.addButton`,
    defaultMessage: 'Add',
  },
  saveButton: {
    id: `${scope}.saveButton`,
    defaultMessage: 'Save',
  },
  cancelButton: {
    id: `${scope}.cancelButton`,
    defaultMessage: 'Cancel',
  },
  saveDraftButton: {
    id: `${scope}.saveDraftButton`,
    defaultMessage: 'Save to draft',
  },
  deleteButton: {
    id: `${scope}.deleteButton`,
    defaultMessage: 'Delete',
  },
  changePhotoButton: {
    id: `${scope}.changePhotoButton`,
    defaultMessage: 'Change photo',
  },
  modelEditProjectHeader: {
    id: `${scope}.modelEditProjectHeader`,
    defaultMessage: 'Edit Project',
  },
  modelAddProjectHeader: {
    id: `${scope}.modelAddProjectHeader`,
    defaultMessage: 'Add Project',
  },
  modelEditProfessionalProfilesHeader: {
    id: `${scope}.modelEditProfessionalProfilesHeader`,
    defaultMessage: 'Edit Professional Profiles',
  },
  modelEditProfessionalSkillsHeader: {
    id: `${scope}.modelEditProfessionalSkillsHeader`,
    defaultMessage: 'Add/Edit Skills',
  },
  modelEditPersonalLanguageHeader: {
    id: `${scope}.modelEditPersonalLanguageHeader`,
    defaultMessage: 'Add/Edit Skills',
  },
  workExperienceEndDatePresent: {
    id: `${scope}.workExperienceEndDatePresent`,
    defaultMessage: 'Present',
  },
  backToTalent: {
    id: `${scope}.backToTalent`,
    defaultMessage: 'Back to talents list',
  },
  backToProjectDetails: {
    id: `${scope}.backToProjectDetails`,
    defaultMessage: 'Back to project details',
  },
  backToAgencyMyTeam: {
    id: `${scope}.backToAgencyMyTeam`,
    defaultMessage: 'Back to my team',
  },
  backToBriefDetail: {
    id: `${scope}.backToBriefDetail`,
    defaultMessage: 'Back to brief detail',
  },
  backToTimesheets: {
    id: `${scope}.backToTimesheets`,
    defaultMessage: 'Back to timesheets',
  },
  sendReqButton: {
    id: `${scope}.sendReqButton`,
    defaultMessage: 'Send request',
  },
  requestToHireButton: {
    id: `${scope}.requestToHireButton`,
    defaultMessage: 'Request to hire',
  },
  scheduleInterviewButton: {
    id: `${scope}.scheduleInterviewButton`,
    defaultMessage: 'Send invite',
  },
  submitButton: {
    id: `${scope}.submitButton`,
    defaultMessage: 'Submit',
  },
  allocateButton: {
    id: `${scope}.allocateButton`,
    defaultMessage: 'Allocate',
  },
  btnConfirm: {
    id: `${scope}.btnConfirm`,
    defaultMessage: 'Confirm',
  },
  btnHire: {
    id: `${scope}.btnHire`,
    defaultMessage: 'Hire',
  },
  btnClearFilter: {
    id: `${scope}.btnClearFilter`,
    defaultMessage: 'Clear all',
  },
  shareLabel: {
    id: `${scope}.shareLabel`,
    defaultMessage: 'Share',
  },
  savePDF: {
    id: `${scope}.savePDF`,
    defaultMessage: 'Save as PDF',
  },
  talentLinkCopiedToClipBoard: {
    id: `${scope}.talentLinkCopiedToClipBoard`,
    defaultMessage: 'Talent link copied to your clipboard.',
  },
  alsoKnows: {
    id: `${scope}.alsoKnows`,
    defaultMessage: 'Also knows',
  },
  permanentEmp: {
    id: `${scope}.permanentEmp`,
    defaultMessage: 'Permanent Employee',
  },
  labelFreelancer: {
    id: `${scope}.labelFreelancer`,
    defaultMessage: 'Freelancer',
  },
  labelSubContractor: {
    id: `${scope}.labelSubContractor`,
    defaultMessage: 'Subcontractor',
  },
  perAnnum: {
    id: `${scope}.perAnnum`,
    defaultMessage: '/ annum',
  },
  perHour: {
    id: `${scope}.perHour`,
    defaultMessage: '/ hr',
  },
  labelValues: {
    id: `${scope}.labelValues`,
    defaultMessage: 'Values',
  },
  labelIndustries: {
    id: `${scope}.labelIndustries`,
    defaultMessage: 'Industries',
  },
  interviewedForSkillsAndExpertise: {
    id: `${scope}.interviewedForSkillsAndExpertise`,
    defaultMessage: 'Interviewed for skills and expertise',
  },
  checkedForEducationAndExperiences: {
    id: `${scope}.checkedForEducationAndExperiences`,
    defaultMessage: 'Checked for education and experiences',
  },
  verifiedPhotoIDandAddress: {
    id: `${scope}.verifiedPhotoIDandAddress`,
    defaultMessage: 'Verified photo ID and address',
  },
  metaDescription: {
    id: `${scope}.metaDescription`,
    defaultMessage: 'is top vetted talent available to hire on Notchup platform',
  },
  maskedContent: {
    id: `${scope}.maskedContent`,
    defaultMessage: 'Interested in seeing more details or more talent like this?',
  },
  maskedClientProfileContent: {
    id: `${scope}.maskedClientProfileContent`,
    defaultMessage: 'Interested in seeing more details?',
  },
  maskedContentBtnLabel: {
    id: `${scope}.maskedContentBtnLabel`,
    defaultMessage: 'Login or Sign up to view',
  },
  publicViewSalaryBtnLabel: {
    id: `${scope}.publicViewSalaryBtnLabel`,
    defaultMessage: 'Login to see salary',
  },
  publicViewAvailibilityBtnLabel: {
    id: `${scope}.publicViewAvailibilityBtnLabel`,
    defaultMessage: 'Login to see availability',
  },
  publicViewCertificationsBtnLabel: {
    id: `${scope}.publicViewCertificationsBtnLabel`,
    defaultMessage: 'Login to see certifications',
  },
  publicViewEducationBtnLabel: {
    id: `${scope}.publicViewEducationBtnLabel`,
    defaultMessage: 'Login to see education',
  },
  publicViewKeyProjectsBtnLabel: {
    id: `${scope}.publicViewKeyProjectsBtnLabel`,
    defaultMessage: 'Login to see key projects',
  },
  publicViewWorkExperienceBtnLabel: {
    id: `${scope}.publicViewWorkExperienceBtnLabel`,
    defaultMessage: 'Login to see work experience',
  },
  noDataMessage1: {
    id: `${scope}.noDataMessage1`,
    defaultMessage: 'An exact match for this URL could not be found.',
  },
  noDataMessagePublicView2: {
    id: `${scope}.noDataMessagePublicView2`,
    defaultMessage:
      'The profile you’re looking for doesn’t exist. To search and hire from a thousands of Notchup talents, log in or join Notchup now.',
  },
  noDataMessagePrivateView2: {
    id: `${scope}.noDataMessagePrivateView2`,
    defaultMessage: 'The profile you’re looking for doesn’t exist. Find thousands of Notchup profiles available to build your project.',
  },
  loginorJoinNotchup: {
    id: `${scope}.loginorJoinNotchup`,
    defaultMessage: 'Login or Join Notchup',
  },
  btnInterview: {
    id: `${scope}.btnInterview`,
    defaultMessage: 'Interview',
  },
  btnScheduleInterview: {
    id: `${scope}.btnScheduleInterview`,
    defaultMessage: 'Schedule Interview',
  },
  teamTitle: {
    id: `${scope}.teamTitle`,
    defaultMessage: 'Team',
  },

  identityProof: {
    id: `${scope}.identityProof`,
    defaultMessage: 'Proof of identity',
  },
  addressProof: {
    id: `${scope}.addressProof`,
    defaultMessage: 'Proof of address',
  },
  registrationCertificate: {
    id: `${scope}.registrationCertificate`,
    defaultMessage: 'Company registration certificate',
  },
  salesNumber: {
    id: `${scope}.salesNumber`,
    defaultMessage: 'Sales / VAT / GST / Other tax number',
  },
  companyInsurence: {
    id: `${scope}.companyInsurence`,
    defaultMessage: 'Company insurance certificate',
  },

  aboutCompany: {
    id: `${scope}.aboutCompany`,
    defaultMessage: 'About Company',
  },

  companyCultures: {
    id: `${scope}.companyCultures`,
    defaultMessage: 'Culture',
  },

  companyType: {
    id: `${scope}.companyType`,
    defaultMessage: 'Type',
  },

  companyIndustry: {
    id: `${scope}.companyIndustry`,
    defaultMessage: 'Industry',
  },

  companyPeople: {
    id: `${scope}.companyPeople`,
    defaultMessage: 'People',
  },

  companyLocations: {
    id: `${scope}.companyLocations`,
    defaultMessage: 'Locations',
  },

  companyTalents: {
    id: `${scope}.companyTalents`,
    defaultMessage: 'Talents',
  },
  noOpenJobs: {
    id: `${scope}.noOpenJobs`,
    defaultMessage: 'No open jobs available',
  },

  companyNoTalents: {
    id: `${scope}.companyNoTalents`,
    defaultMessage: 'No talents to display',
  },
  companyGlassdoorRating: {
    id: `${scope}.companyGlassdoorRating`,
    defaultMessage: 'Overall Rating',
  },
  editText: {
    id: `${scope}.editText`,
    defaultMessage: 'Edit',
  },
});
