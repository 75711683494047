import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { Badge, Button, P, ToastifyMessage } from 'components';
import { API_URL, circleTickIcon, editNoteIcon, errorIcon, locationIcon, TALENT, VERSION2 } from 'containers/App/constants';
import { toast } from 'react-toastify';
import { primaryDarkNew, primaryNew, white } from 'themes/variables';
import request from 'utils/request';
import SVG from 'react-inlinesvg';
import { getTooltip } from '../Dashboard/SkillsList';

export const getNode = (data, id) => ({
  id: String(id + 1),
  type: 'customNode',
  position: { x: data?.coordinates?.[0], y: data?.coordinates?.[1] },
  data,
});

export const fetchNodeData = async ({ setIsLoading, setCareerPlanData, payload = {} }) => {
  setIsLoading(true);
  try {
    const data = {
      method: 'POST',
      body: { ...payload },
    };
    const requestURL = `${API_URL}${VERSION2}${TALENT}/career-plan`;
    const response = await request(requestURL, data);
    if (response?.status && response?.data) {
      setCareerPlanData(response.data);
    } else {
      toast.error(<ToastifyMessage message={response?.message} type="error" />, { className: 'Toast-error' });
    }
  } finally {
    setIsLoading(false);
  }
};

export const updateTalentDesiredRole = async (payload = {}) => {
  const data = {
    method: 'PUT',
    body: payload,
  };
  const requestURL = `${API_URL}${VERSION2}${TALENT}/set-desired-role`;
  const response = await request(requestURL, data);
  if (!response?.status) {
    toast.error(<ToastifyMessage message={response.message} type="error" />, { className: 'Toast-error' });
  }
};

export const CustomNode = ({ data }) => {
  const { nodeTitle, image, careerName, experienceRequired, isSelected } = data;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '16px',
        padding: '16px',
        backgroundColor: isSelected ? '#1833CC26' : '#F4F6FD',
        border: `1px solid rgb(${primaryNew}, ${isSelected ? '1' : '0.1'})`,
        // height: '50px',
        color: `rgb(${primaryDarkNew})`,
        fontFamily: 'Arial, sans-serif',
        position: 'relative',
      }}
    >
      {/* Top Label */}

      {nodeTitle && (
        <div
          style={{
            position: 'absolute',
            top: '-30px',
            left: '16px',
            fontSize: '12px',
            color: `rgb(${primaryNew})`,
            fontWeight: 'bold',
          }}
        >
          {nodeTitle}
        </div>
      )}

      {/* Profile Picture */}
      {image && (
        <img
          src={image}
          alt="Profile"
          style={{
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            objectFit: 'cover',
            marginRight: '12px',
          }}
        />
      )}

      {/* Text Content */}
      <div>
        <P className="p14 fw-bold mb-1" style={{ color: `rgb(${primaryDarkNew})` }}>
          {careerName}
        </P>
        <P className="p12 mb-0" opacityVal={0.5}>
          {experienceRequired}+ yrs
        </P>
      </div>
      <Handle type="target" position={Position.Left} id="top" style={{ top: '20%' }} />
      <Handle type="target" position={Position.Left} id="middle" style={{ top: '50%' }} />
      <Handle type="target" position={Position.Left} id="bottom" style={{ top: '80%' }} />
      {/* <Handle type="source" position={Position.Right} style={{ background: '#3B5FFF' }} /> */}
      <Handle type="source" position={Position.Right} id="top" style={{ top: '20%' }} />
      <Handle type="source" position={Position.Right} id="middle" style={{ top: '50%' }} />
      <Handle type="source" position={Position.Right} id="bottom" style={{ top: '80%' }} />
    </div>
  );
};

export const getLabels = (completeList, existingItems = []) => {
  const finalList = [];

  for (const type of completeList) {
    if (finalList.length === existingItems.length) {
      break;
    } // Stop when enough labels are found

    if (existingItems.includes(type.value)) {
      finalList.push(type.label);
    }
  }

  return finalList;
};

export const CustomCardNode = ({ data = {}, userData = {}, selectedNodeData = {} }) => {
  const { dataPoints = [], title, titleKey = null, btnData = {}, edit = {} } = data;
  const { showBtn, name, onClick, btnClass = '' } = btnData;
  const { onClick: editClick } = edit;
  return (
    <div
      style={{
        background: `rgb(${white})`,
        borderRadius: '10px',
        padding: '15px',
        border: `1px solid rgb(${primaryNew}, 0.1)`,
      }}
    >
      <P className="p14 mb-2 fw-bolder d-flex justify-content-between">
        {title}
        {editClick && (
          <Button className="btn-link btn opacity-50" onClick={() => editClick(true)}>
            <SVG src={editNoteIcon} />
          </Button>
        )}
      </P>
      {titleKey && selectedNodeData?.[titleKey] && <P className="p14 mb-2">{selectedNodeData[titleKey]}</P>}
      {dataPoints.map((dataPoint) => {
        const { question, answerKey, renderFn } = dataPoint;
        return (
          <div key={answerKey} className="mb-2">
            <P className="p12 mb-1" opacityVal="0.5">
              {question}
            </P>
            <P className="p12 mb-1">{renderFn?.(selectedNodeData?.[answerKey] || userData?.[answerKey]) || userData[answerKey]}</P>
          </div>
        );
      })}
      {showBtn && (
        <Button type="button" className={`btn ${btnClass || 'btn-outline'} btn-sm mt-1 w-100`} onClick={onClick}>
          {name}
        </Button>
      )}
    </div>
  );
};

export const currentRoleDetails = {
  // Adjust X and Y values to position it at the top-right
  title: 'Your Current Role',
  dataPoints: [
    {
      question: 'What is your current role?',
      answerKey: 'primaryRole',
    },
    {
      question: 'Years of experience',
      answerKey: 'yearsOfExperience',
    },
  ],
};

export const targetRoleCardData = ({ handleClick, handleBtnClick }) => ({
  title: 'Your Target Role',
  dataPoints: [
    {
      question: 'Your desired role',
      answerKey: 'careerName',
    },
    {
      question: 'Company type',
      answerKey: 'companyType',
    },
    {
      question: 'Industry',
      answerKey: 'industry',
    },
    {
      question: 'Location',
      answerKey: 'location',
    },
  ],
  edit: {
    onClick: handleClick,
  },
  btnData: {
    name: 'Upskill now',
    onClick: handleBtnClick,
    showBtn: true,
    btnClass: 'btn-primary',
  },
});

export const desiredRoleCardData = ({ handleClick, showBtn = false, handleSkillClick }) => ({
  title: 'How do you match for',
  titleKey: 'careerName',
  dataPoints: [
    {
      question: 'Technical skills required',
      answerKey: 'skills',
      renderFn: (data) => renderRequiredSkills({ skills: data, onClick: handleSkillClick, type: 'skills' }),
    },
    {
      question: 'Soft skills required',
      answerKey: 'softSkills',
      renderFn: (data) => renderRequiredSkills({ skills: data }),
    },
    { question: 'Years of experience', answerKey: 'experienceRequired', renderFn: (data) => `${data}+ yrs` },
  ],
  btnData: {
    name: 'Set as target role',
    onClick: handleClick,
    showBtn,
  },
});

const getSanitizedId = (name) => name?.replace(/\s+/g, '-').toLowerCase();

const renderRequiredSkills = ({ skills = [], onClick = null, type = 'softSkills' }) =>
  skills.map((skill) => {
    const tooltipId = `tooltip-${getSanitizedId(skill.name)}`;
    return (
      <>
        <Badge
          className={`badge-sm primary fit-content m-1 ${skill.url ? 'cursor-pointer' : ''}`}
          key={skill?.name || skill}
          onClick={() => skill?.url && onClick?.(skill)}
          data-tooltip-id={tooltipId}
          id={tooltipId}
        >
          <P className="p12 mb-0 py-0 px-1" opacityVal="0.5">
            {skill?.name || String(skill)}
            {skill?.hasOwnProperty('isNew') && (
              <SVG src={skill?.isNew ? errorIcon : circleTickIcon} className="ms-1" width={10} height={10} />
            )}
          </P>
        </Badge>
        {type === 'skills' &&
          skill?.url &&
          getTooltip({
            id: tooltipId,
            content: <P className="p12 mb-0 py-0 px-0 text-white">Click to start test</P>,
          })}
      </>
    );
  });

export const showNodeTitle = ({ icon, title, type = 'target' }) => (
  <Badge
    className={`badge-sm font-0 fit-content py-1 px-2 d-flex aling-items-center ${type === 'primary' ? 'secondary' : 'success'}`}
    key={title}
  >
    <SVG className="me-1" src={icon || locationIcon} width={14} height={14} />
    <P className={`p12 m-0 lh-1 align-self-center ${type === 'primary' ? 'primaryNew' : 'success'}`}>{title || ''}</P>
  </Badge>
);
